// $theme-color: #cc0d0d;
$theme-color: #e52727;

$theme-scheme: () !default;
$theme-scheme: map_merge(
  (
    main: $theme-color,
    hover: rgba(#000, 0.12),
  ),
  $theme-scheme
);

@mixin define-header-variables($header-variant-name) {
  // ----------------------------------------
  // -- classic-variant-two
  // ----------------------------------------
  @if ($header-variant-name == classic-two) {
    // topbar
    $topbar-classic-scheme: (
      main: adjust-color($theme-color, $lightness: -7, $saturation: -5),
    ) !default !global;

    // logo
    $logo-scheme: (
      primary: rgba(#fff, 0.9),
      secondary: rgba(#fff, 0.6),
    ) !default !global;
  }

  // ----------------------------------------
  // -- classic-variant-four
  // ----------------------------------------
  @if ($header-variant-name == classic-four) {
    // logo
    $logo-scheme: (
      primary: rgba(#fff, 0.9),
      secondary: rgba(#fff, 0.6),
    ) !default !global;
  }

  // ----------------------------------------
  // -- mobile-variant-two
  // ----------------------------------------
  @if ($header-variant-name == mobile-two) {
    // logo
    $logo-scheme: (
      primary: rgba(#fff, 0.9),
      secondary: rgba(#fff, 0.6),
    ) !default !global;
  }
}
