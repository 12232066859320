// @import "../../../styles/settings.scss";

.container {
  min-height: 250px;
  margin: 25px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;

  @keyframes change-opacity {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  @keyframes change-image {
    from {
      opacity: 1;
    }

    50% {
      opacity: 1;
    }

    to {
      opacity: 0;
    }
  }

  @keyframes change-text {
    from {
      opacity: 0;
    }

    25% {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  .block {
    position: absolute !important;
  }

  .image {
    // width: 200px;
    // height: 100px;
    // display: flex;
    // justify-content: center;

    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;

    animation: change-image 2s ease-in-out infinite; // alternate;
    animation-fill-mode: forwards;

    img {
      width: 100%;
      height: auto;
    }
  }
}
